exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-forms-tsx": () => import("./../../../src/pages/forms.tsx" /* webpackChunkName: "component---src-pages-forms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-pelvic-health-dysfunction-lecture-tsx": () => import("./../../../src/pages/news/pelvic-health-dysfunction-lecture.tsx" /* webpackChunkName: "component---src-pages-news-pelvic-health-dysfunction-lecture-tsx" */),
  "component---src-pages-news-tahoe-daily-tribune-tsx": () => import("./../../../src/pages/news/tahoe-daily-tribune.tsx" /* webpackChunkName: "component---src-pages-news-tahoe-daily-tribune-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-offerings-aquatic-therapy-tsx": () => import("./../../../src/pages/offerings/aquatic-therapy.tsx" /* webpackChunkName: "component---src-pages-offerings-aquatic-therapy-tsx" */),
  "component---src-pages-offerings-kinesio-taping-tsx": () => import("./../../../src/pages/offerings/kinesio-taping.tsx" /* webpackChunkName: "component---src-pages-offerings-kinesio-taping-tsx" */),
  "component---src-pages-offerings-lvst-tsx": () => import("./../../../src/pages/offerings/lvst.tsx" /* webpackChunkName: "component---src-pages-offerings-lvst-tsx" */),
  "component---src-pages-offerings-orthotics-tsx": () => import("./../../../src/pages/offerings/orthotics.tsx" /* webpackChunkName: "component---src-pages-offerings-orthotics-tsx" */),
  "component---src-pages-offerings-pelvic-health-tsx": () => import("./../../../src/pages/offerings/pelvic-health.tsx" /* webpackChunkName: "component---src-pages-offerings-pelvic-health-tsx" */),
  "component---src-pages-offerings-remote-therapeutic-monitoring-tsx": () => import("./../../../src/pages/offerings/remote-therapeutic-monitoring.tsx" /* webpackChunkName: "component---src-pages-offerings-remote-therapeutic-monitoring-tsx" */),
  "component---src-pages-offerings-tsx": () => import("./../../../src/pages/offerings.tsx" /* webpackChunkName: "component---src-pages-offerings-tsx" */),
  "component---src-pages-offerings-vestibular-rehabilitation-tsx": () => import("./../../../src/pages/offerings/vestibular-rehabilitation.tsx" /* webpackChunkName: "component---src-pages-offerings-vestibular-rehabilitation-tsx" */),
  "component---src-pages-questions-tsx": () => import("./../../../src/pages/questions.tsx" /* webpackChunkName: "component---src-pages-questions-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

